import {observer} from "mobx-react-lite";

import {useStore} from "../store";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Button, Card, H2, H3, HTMLTable} from "@blueprintjs/core";
import {prettifyValue} from "../utils/utils";

import './share-view.scss'

const clusterCellClass = (row: any) : string => {
    let className = '';
    if (row === undefined || row.length < 3) return ''
    if (row[0] > row[1]) {
        className += 'more-buy'
    } else {
        className += 'more-sell'
    }
    if (row[3] && row[3].indexOf('*') > -1) {
        className += ' max-qty'
    }
    if (row[3] && row[3].indexOf('C') > -1) {
        className += ' close-price'
    }
    if (row[3] && row[3].indexOf('O') > -1) {
        className += ' open-price'
    }

    return className
}

const getTickerLogo = (ticker: string): string => {
    return `url(/images/` + ticker + `x160.png)`
}

const ShareView = observer(() => {

    const store = useStore()
    const [shareInfo, setShareInfo] = useState<any>()
    const [mdata, setMdata] = useState<any>()
    const [futures, setFutures] = useState<any[]>()
    const [dividends, setDividends] = useState<any[]>()
    const {ticker} = useParams()
    const [clusters, setClusters] = useState<any[]>([]);


    useEffect(() => {
        if (ticker) {
            store.getShareInfo(ticker).then(shareInfo => {
                setShareInfo(shareInfo)
            }).catch(error => {
                console.error(error)
            })

            store.getShareMdata(ticker).then(mdata => {
                setMdata(mdata)
            }).catch(error => {
                console.log(error)
            })

            store.getShareFutures(ticker).then(futures => {
                setFutures(futures)
            }).catch(error => {
                console.log(error)
            })

            store.getDividends(ticker).then(dividends => {
                setDividends(dividends.reverse())
            }).catch(error => {
                console.error(error)
            })

            store.getTradeClusters(ticker).then(clusters => {
                setClusters(clusters)
            }).catch(error => {
                console.error(error)
            })

        }
    }, [store, ticker]);

    const updateClusters = () => {
        if (ticker) {
            store.getTradeClusters(ticker).then(clusters => {
                setClusters(clusters)
            }).catch(error => {
                console.error(error)
            })
        }
    }

    return (
        <div className="share-view">
            <Card>
                <div className="share-title">
                    <div className="logo-name">
                        <span className="logo-large" style={{backgroundImage: getTickerLogo(shareInfo?.sec_id)}}/>
                        <div className="ticker-with-name">
                            <H2>{shareInfo?.sec_id}</H2>
                            <H3>{shareInfo?.sec_name}</H3>
                        </div>
                    </div>
                    <div className="price-value-header">
                        <div className="price-header">
                            {mdata?.last_price}₽
                        </div>
                        <div className="value-header">
                            {prettifyValue(mdata?.value)}
                        </div>
                    </div>
                </div>
            </Card>
            <div className="dividends-futures-block">
                <Card className="dividends-block">
                    <H3>Дивиденды</H3>
                    <HTMLTable compact striped interactive className="dividends-table">
                        <tbody>
                        {dividends?.map(dividend => {
                            return (
                                <tr>
                                    <td>{dividend.registry_close_date}</td>
                                    <td>{dividend.value}₽</td>
                                    <td>{dividend.yield_percent}%</td>
                                    <td>{dividend.close_days ? dividend.close_days + ' д.': '-'}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </HTMLTable>
                </Card>
                <Card>
                    <H3>Фьючерсы</H3>
                    <HTMLTable compact striped bordered interactive>
                        <thead>
                        <tr>
                            <th>ID</th>
                            <th>Название</th>
                            <th>Лотность</th>
                            <th>Гар. обеспечение</th>
                            <th>Последняя цена</th>
                            <th>Справедливая цена</th>
                            <th>Контанго</th>
                            <th>Объем</th>
                            <th>Экспирация</th>
                        </tr>
                        </thead>
                        <tbody>
                        {futures?.map(future => {
                            return (
                                <tr>
                                    <td>{future.sec_id}</td>
                                    <td>{future.info.short_name}</td>
                                    <td>{future.info.lot_volume}</td>
                                    <td>{future.info.initial_margin}</td>
                                    <td>{future.set_tle_price}</td>
                                    <td>{future.fair_price}</td>
                                    <td>{future.delta}</td>
                                    <td>{prettifyValue(future.value)}</td>
                                    <td>{future.info.last_del_date}&nbsp;({future.days_left} д.)</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </HTMLTable>
                </Card>
            </div>
            <div className="trade-clusters">
                <Card>
                    <H3>Торговые кластеры&nbsp;<Button icon="updated" onClick={() => updateClusters()}/></H3>
                    <HTMLTable className="clusters-table" compact striped interactive bordered>
                        <thead>
                        <tr>
                            <th>Цена</th>
                            <th>10:00</th>
                            <th>11:00</th>
                            <th>12:00</th>
                            <th>13:00</th>
                            <th>14:00</th>
                            <th>15:00</th>
                            <th>16:00</th>
                            <th>17:00</th>
                            <th>18:00</th>
                            <th>19:00</th>
                            <th>20:00</th>
                            <th>Итог</th>
                        </tr>
                        </thead>
                        <tbody>
                        {clusters.map((row) => {
                            return (
                                <tr>
                                    <td>{row[0]}</td>
                                    <td className={clusterCellClass(row[3])}>{prettifyValue(row[3][2])}</td>
                                    <td className={clusterCellClass(row[4])}>{prettifyValue(row[4][2])}</td>
                                    <td className={clusterCellClass(row[5])}>{prettifyValue(row[5][2])}</td>
                                    <td className={clusterCellClass(row[6])}>{prettifyValue(row[6][2])}</td>
                                    <td className={clusterCellClass(row[7])}>{prettifyValue(row[7][2])}</td>
                                    <td className={clusterCellClass(row[8])}>{prettifyValue(row[8][2])}</td>
                                    <td className={clusterCellClass(row[9])}>{prettifyValue(row[9][2])}</td>
                                    <td className={clusterCellClass(row[10])}>{prettifyValue(row[10][2])}</td>
                                    <td className={clusterCellClass(row[11])}>{prettifyValue(row[11][2])}</td>
                                    <td className={clusterCellClass(row[12])}>{prettifyValue(row[12][2])}</td>
                                    <td className={clusterCellClass(row[13])}>{prettifyValue(row[13][2])}</td>
                                    <td className={clusterCellClass(row[1])}>{prettifyValue(row[1][2])}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </HTMLTable>
                </Card>
            </div>
        </div>
    )
})

export default ShareView