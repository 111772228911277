import {observer} from "mobx-react-lite";
import {useStore} from "../store";
import React, {useEffect} from "react";
import {Card, Divider, Elevation, H2, H3, H5, HTMLTable, Icon} from "@blueprintjs/core";

import './stock-view.scss'
import {BlueprintIcons_16Id} from "@blueprintjs/icons/src/generated/16px/blueprint-icons-16";

function prettifyValue(value: number): string {
    if (value >= 1000 && value < 1000000) {
        return (value / 1000).toFixed(2).toString() + "K"
    } else if (value >= 1000000 && value < 1000000000) {
        return (value / 1000000).toFixed(2).toString() + "M"
    } else if (value >= 1000000000) {
        return (value / 1000000000).toFixed(2).toString() + "B"
    }
    return "-"
}

function getGrownClass(grow_percent: number): string {
    if (grow_percent > 0) {
        return "positive-grow"
    } else {
        return "negative-grow"
    }
}

const getGrowCaret = (grow: number): BlueprintIcons_16Id => {
    if (grow > 0) {
        return "caret-up"
    } else {
        return "caret-down"
    }
}

const StockView = observer(() => {

    const store = useStore()

    useEffect(() => {
        store.loadDailyStockView()
    }, [store]);

    return (
        <div className="stock-view">
            <div className="imoex-state">
                <H2>Индекс мосбиржи</H2>
                <H3>
                    Сегодня:&nbsp;{store.dailyView['imoex'].current_value}&nbsp;
                    <span className={getGrownClass(store.dailyView['imoex'].grow_percent)}>
                        (
                        <Icon className="workspace__sidebar-link-icon"
                              icon={getGrowCaret(store.dailyView['imoex'].grow_percent)} size={32}/>
                        {store.dailyView['imoex'].grow_percent}%
                        )
                    </span>
                </H3>
                <H3>Вчера:&nbsp;{store.dailyView['imoex'].last_value}</H3>
                <H3>Объем:&nbsp;{prettifyValue(store.dailyView['imoex'].value)}</H3>
            </div>
            <Divider/>
            <div className="cards-block">
                <Card interactive={true} elevation={Elevation.TWO}>
                    <H5>Акции с наибольшим ростом</H5>
                    <HTMLTable className="daily-stat-table" striped compact>
                        {store.dailyView['grown'].map((row: any) => {
                            return (
                                <tr key={row.security_id}>
                                    <td>{row.short_name}</td>
                                    <td>({row.security_id})</td>
                                    <td>
                                        <span className="positive-grow">
                                            <Icon className="workspace__sidebar-link-icon"
                                                  icon={getGrowCaret(row.growth_percent)}
                                                  size={16}
                                            />
                                            {row.growth_percent}%
                                        </span>
                                    </td>
                                    <td>
                                        <span className="positive-grow">({row.last_price}&nbsp;₽)</span>
                                    </td>
                                </tr>
                            )
                        })}
                    </HTMLTable>
                </Card>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <H5>Акции с наибольшим объемом</H5>
                    <HTMLTable className="daily-stat-table" striped compact>
                        {store.dailyView['high_value'].map((row: any) => {
                            return (
                                <tr key={row.security_id}>
                                    <td>{row.short_name}</td>
                                    <td>({row.security_id})</td>
                                    <td><span className="positive-grow">{prettifyValue(row.value)}</span></td>
                                    <td>({row.last_price}₽)</td>
                                </tr>
                            )
                        })}
                    </HTMLTable>
                </Card>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <H5>Акции с наибольшим падением</H5>
                    <HTMLTable className="daily-stat-table" striped compact>
                        {store.dailyView['fallen'].map((row: any) => {
                            return (
                                <tr key={row.security_id}>
                                    <td>{row.short_name}</td>
                                    <td>({row.security_id})</td>
                                    <td>
                                    <span className="negative-grow">
                                        <Icon className="workspace__sidebar-link-icon"
                                              icon={getGrowCaret(row.growth_percent)}
                                              size={16}
                                        />
                                        {Math.abs(row.growth_percent)}%
                                    </span>
                                    </td>
                                    <td><span className="negative-grow">({row.last_price}&nbsp;₽)</span></td>
                                </tr>
                            )
                        })}
                    </HTMLTable>
                </Card>
                <Card interactive={true} elevation={Elevation.TWO}>
                    <H5>Акции с наименьшим объемом</H5>
                    <HTMLTable className="daily-stat-table" striped compact>
                    {store.dailyView['low_value'].map((row: any) => {
                        return (
                            <tr key={row.security_id}>
                                <td>{row.short_name}</td>
                                <td>({row.security_id})</td>
                                <td>
                                    <span className="negative-grow">{prettifyValue(row.value)}</span>
                                </td>
                                <td>
                                    ({row.last_price}₽)
                                </td>
                            </tr>
                        )
                    })}
                    </HTMLTable>
                </Card>
            </div>
        </div>
    )
})

export default StockView