import {createBrowserRouter} from "react-router-dom";
import Workspace from "../Workspace";
import IdeasView from "../views/IdeasView";
import StockView from "../views/StockView";
import ShareView from "../views/ShareView";
import FuturesView from "../views/FuturesView";
import SharesView from "../views/SharesView";

const router = createBrowserRouter([
    {
        path: "/", element: <Workspace/>, children:
            [
                {
                    path: "/stock/index", element: <StockView/>
                },
                {
                    path: "/stock/shares", element: <SharesView/>
                },
                {
                    path: "/stock/futures", element: <FuturesView/>
                },
                {
                    path: "/ideas", element: <IdeasView/>, children: [
                        {path: ":userName", element: <IdeasView/>},
                    ]
                },
                {
                    path: "/shares/:ticker", element: <ShareView/>,
                }
            ]
    },
])

export {router}