const prettifyValue = (value: number): string  => {
    if (value === undefined || value === null) return ''
    if (value >= 1000 && value < 1000000) {
        return (value / 1000).toFixed(2).toString() + "K"
    } else if (value >= 1000000 && value < 1000000000) {
        return (value / 1000000).toFixed(2).toString() + "M"
    } else if (value >= 1000000000) {
        return (value / 1000000000).toFixed(2).toString() + "B"
    }
    return value.toString()
}

export { prettifyValue }