import {Alignment, Button, Icon, Navbar} from "@blueprintjs/core";
import {observer} from "mobx-react-lite";
import React, {useEffect} from "react";
import {NavLink, Outlet} from "react-router-dom";

import './workspace.scss'
import {useStore} from "./store";

const Workspace = observer(() => {

    const store = useStore()

    const logout = () => {
        store.logout()
    }

    useEffect(() => {
        store.loadSubscriptions()
    }, [store]);

    return (
        <div className="application bp5-dark">
            <Navbar>
                {store.sidebarVisible && <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading className="logo-block">Shapito</Navbar.Heading>
                    <Button icon="arrow-left" minimal onClick={() => store.toggleSidebarVisible()}></Button>
                    <Navbar.Divider/>
                </Navbar.Group>}
                {!store.sidebarVisible && <Navbar.Group align={Alignment.LEFT}>
                    <Button icon="arrow-right" minimal onClick={() => store.toggleSidebarVisible()}></Button>
                    <Navbar.Divider/>
                </Navbar.Group>}
                <Navbar.Group align={Alignment.RIGHT}>
                    <Button className="bp5-minimal" icon="user" onClick={logout}/>
                </Navbar.Group>
            </Navbar>
            <div className="workspace">
                <div className="workspace__sidebar-container"
                     // style={{minWidth: store.sidebarVisible ? '250px' : '0'}}>
                     style={{display: store.sidebarVisible ? 'block' : 'none'}}>
                    <Sidebar subscriptions={store.subscriptions}/>
                </div>
                <div className="workspace__page-container">
                    <Outlet/>
                </div>
            </div>
        </div>
    )
})

interface ISidebarProps {
    subscriptions: []
}

const Sidebar = ({subscriptions}: ISidebarProps) => {
    return (
        <div className="workspace__sidebar">
            <div className="workspace__sidebar-content">
                <div className="workspace__sidebar-nav-link">
                    <Icon className="workspace__sidebar-link-icon" icon="dashboard" color="#32A467" size={16}/>
                    <span className="workspace__sidebar-link-name">Рынок</span>
                </div>
                <NavLink className="workspace__sidebar-nav-sublink" to={"/stock/index"}>
                    <span className="workspace__sidebar-link-name">Индекс</span>
                </NavLink>
                <NavLink className="workspace__sidebar-nav-sublink" to={"/stock/shares"}>
                    <span className="workspace__sidebar-link-name">Акции</span>
                </NavLink>
                <NavLink className="workspace__sidebar-nav-sublink" to={"/stock/futures"}>
                    <span className="workspace__sidebar-link-name">Фьючерсы</span>
                </NavLink>
                <div className="workspace__sidebar-nav-link">
                    <Icon className="workspace__sidebar-link-icon" icon="lightbulb" color="#F0B726" size={16}/>
                    <span className="workspace__sidebar-link-name">Идеи</span>
                </div>
                <NavLink className="workspace__sidebar-nav-sublink" to={"/ideas"} end>
                    <span className="workspace__sidebar-link-name">Мои</span>
                </NavLink>
                {subscriptions.map((subcription: any) => {
                    return (<NavLink className="workspace__sidebar-nav-sublink" to={"/ideas/" + subcription.username}>
                        <span className="workspace__sidebar-link-name">{subcription.username.toUpperCase()}</span>
                    </NavLink>)
                })}
            </div>
        </div>
    )
}

export default Workspace