import {RootStore} from "./index";
import {makeAutoObservable} from "mobx";
import {AuthService} from "../api/auth";
import BootstrapService from "../api/bootstrap";

enum BootstrapMode {
    INIT_MODE,
    AUTH_MODE,
    LOAD_MODE,
    APP_MODE
}

class BootstrapStore {

    root: RootStore;
    mode: BootstrapMode;
    message: string;
    loadProgress: number;

    constructor(root : RootStore) {
        this.mode = BootstrapMode.INIT_MODE;
        this.message = 'Connecting to application service...'
        this.loadProgress = 0;
        this.root = root;
        makeAutoObservable(this);
    }

    currentMode(): BootstrapMode {
        return this.mode;
    }

    init() {
        this.message = 'Initialize bootstrap...'
        BootstrapService.ping().then(_ => {
            this.message = 'Checking last user session...'
            AuthService.loadCurrentUser().then(response => {
                this.root.setCurrentUser(response.data)
                this.mode = BootstrapMode.LOAD_MODE
            }).catch(_ => {
                this.mode = BootstrapMode.AUTH_MODE
            })
        }).catch(error => {
            console.log('error', error)
            this.message = 'Error: ' + error
        })
    }

    login(username: string, password: string) {
        AuthService.login(username, password).then(response => {
            const {access_token} = response.data
            localStorage.setItem("access_token", access_token)
            AuthService.loadCurrentUser().then(response => {
                this.root.setCurrentUser(response.data)
                this.mode = BootstrapMode.LOAD_MODE
            }).catch(_ => {
                this.message = 'Error in user loading'
                this.mode = BootstrapMode.AUTH_MODE
            })
        }).catch(_ => {
            this.message = 'Username or password is incorrect'
        })
    }

    async load() {
        this.message = 'Loading stock data...'
        this.mode = BootstrapMode.APP_MODE
        await this.root.router.navigate("/ideas")
    }
}

export {BootstrapMode, BootstrapStore}