import {instance} from "./index";

const SubscriptionsService = {

    loadSubscriptions() {
        return instance.get("/subscriptions")
    }

}

export default SubscriptionsService