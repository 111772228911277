import {observer} from "mobx-react-lite";
import {useStore} from "../store";
import React, {useEffect, useState} from "react";
import {H3, HTMLTable, Icon} from "@blueprintjs/core";

import {Link} from "react-router-dom";
import {BlueprintIcons_16Id} from "@blueprintjs/icons/src/generated/16px/blueprint-icons-16";

import './shares-view.scss'

function prettifyValue(value: number): string {
    if (value >= 1000 && value < 1000000) {
        return (value / 1000).toFixed(2).toString() + "K"
    } else if (value >= 1000000 && value < 1000000000) {
        return (value / 1000000).toFixed(2).toString() + "M"
    } else if (value >= 1000000000) {
        return (value / 1000000000).toFixed(2).toString() + "B"
    }
    return "-"
}

const getGrowCaret = (grow: number): BlueprintIcons_16Id => {
    if (grow > 0) {
        return "caret-up"
    } else {
        return "caret-down"
    }
}

function getGrownClass(grow_percent: number): string {
    if (grow_percent > 0) {
        return "positive-grow"
    } else {
        return "negative-grow"
    }
}

const SharesView = observer(() => {

    const store = useStore()
    const [shares, setShares] = useState<any[]>([])

    useEffect(() => {
        store.getShares().then(shares => {
            setShares(shares)
        }).catch(error => {
            console.error(error)
        })
    }, [store]);


    return (
        <div className="shares-view">
            <H3>Акции</H3>
            <HTMLTable bordered compact interactive striped>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Эмитент</th>
                    <th className="level-cell">Уровень</th>
                    <th className="current-price-cell">Цена</th>
                    <th>Объем</th>
                </tr>
                </thead>
                <tbody>
                {shares.map(share => {
                    return (
                        <tr>
                            <td><Link to={'/shares/' + share.info.sec_id}>{share.info.sec_id}</Link></td>
                            <td>{share.info.sec_name}</td>
                            <td className="level-cell">{share.info.list_level}</td>
                            <td className="current-price-cell">
                                {share.mdata.last_price}
                                <span className={getGrownClass(share.mdata.last_to_prev_price)}>
                                (
                                <Icon className="workspace__sidebar-link-icon"
                                      icon={getGrowCaret(share.mdata.last_to_prev_price)}
                                      size={16}
                                />
                                    {Math.abs(share.mdata.last_to_prev_price)}%
                                )
                                    </span>
                            </td>
                            <td>{prettifyValue(share.mdata.value)}</td>
                        </tr>
                    )
                })}
                </tbody>
            </HTMLTable>
        </div>
    )
})

export default SharesView