import {observer} from "mobx-react-lite";
import {Button, H1, H3, HTMLTable, Icon, NonIdealState} from "@blueprintjs/core";
import React, {useEffect, useState} from "react";
import {useStore} from "../store";

import './ideas-view.scss'
import {BlueprintIcons_16Id} from "@blueprintjs/icons/src/generated/16px/blueprint-icons-16";
import NewIdeaDialog from "../dialogs/NewIdeaDialog";
import {Link, useParams} from "react-router-dom";

const decisionClass = (decision: string): string => {
    switch (decision) {
        case 'BUY':
            return 'decision-cell decision-buy'
        case 'HOLD':
            return 'decision-cell decision-hold'
        case 'NOT HOLD':
            return 'decision-cell decision-not-hold'
    }
    return '';
}

function getGrownClass(grow_percent: number): string {
    if (grow_percent > 0) {
        return "positive-grow"
    } else {
        return "negative-grow"
    }
}

const getTickerLogo = (ticker: string): string => {
    return `url(/images/` + ticker + `x160.png)`
}

const getGrowCaret = (grow: number): BlueprintIcons_16Id => {
    if (grow > 0) {
        return "caret-up"
    } else {
        return "caret-down"
    }
}

const IdeasView = observer(() => {

    const store = useStore()
    const [showNewIdeaDialog, setShowNewIdeaDialog] = useState<boolean>(false)
    const {userName} = useParams()


    useEffect(() => {
        store.init(userName)
    }, [store, userName]);

    const closeDialog = () => {
        setShowNewIdeaDialog(false)
    }

    const newIdeaCreated = (ticker: string, targetPrice: number, decision: string) => {
        console.log(ticker, targetPrice, decision)
        store.createIdea(ticker, targetPrice, decision)
        closeDialog()
    }

    const ideasHeader = (userName: string | undefined) => {
        if (userName) {
            return (
                <div className="ideas-header">
                    <H3>Идеи {userName.toUpperCase()}</H3>
                </div>

            )
        } else {
            return (
                <div className="ideas-header">
                    <H1>Мои идеи</H1>
                    <Button onClick={() => {setShowNewIdeaDialog(true)}} icon="add">Добавить идею</Button>
                </div>
            )
        }
    }

    return (
        <div className="ideas-view">
            <NewIdeaDialog
                isOpen={showNewIdeaDialog}
                onClose={closeDialog}
                onSubmit={newIdeaCreated}
            />
            {ideasHeader(userName)}
            {store.ideas.length > 0 &&
            <HTMLTable className="ideas-view__table" compact striped bordered interactive>
                <thead>
                <tr>
                    <th>Эмитент</th>
                    <th>Тикер</th>
                    <th className="current-price-cell">Текущая цена</th>
                    <th className="dividend-cell">Дивиденд</th>
                    <th>Таргет</th>
                    <th>Апсайд</th>
                    <th>Мнение</th>
                    <th>Описание</th>
                </tr>
                </thead>
                <tbody>
                {store.ideas.map((idea: any) => {
                    return (
                        <tr key={idea.ticker}>
                            <td className="issuer-cell">
                                <span style={{position: "relative"}}>
                                    <span className="logo-small" style={{width: "20px", backgroundImage: getTickerLogo(idea.ticker)}}/>
                                    <span style={{marginLeft: "26px"}}>{idea.issuer_name}</span>
                                </span>
                            </td>
                            <td className="ticker-cell"><span><Link to={'/shares/' + idea.ticker}>{idea.ticker}</Link></span></td>
                            <td className="current-price-cell">
                                <span>{idea.current_price}</span>
                                <span className={getGrownClass(idea.growth_percent)}>
                                (
                                <Icon className="workspace__sidebar-link-icon"
                                      icon={getGrowCaret(idea.growth_percent)}
                                      size={16}
                                />
                                    {Math.abs(idea.growth_percent)}%
                                )
                                    </span>
                            </td>
                            <td className="dividend-cell">
                                {idea.dividend_value ?
                                    idea.dividend_value + ' ₽ (' + idea.dividend_yield + '%, ' + idea.dividend_days + 'д.)'
                                    : '-'}
                            </td>
                            <td className="target-price-cell">{idea.target_price}</td>
                            <td className="upside-cell">{idea.upside}%</td>
                            <td className={decisionClass(idea.decision)}>{idea.decision}</td>
                            <td className="description-cell">{idea.description}</td>
                        </tr>
                    )
                })}
                </tbody>
            </HTMLTable>
            }
            {store.ideas.length === 0 && <NonIdealState icon="lightbulb" title="У пользователя пока нет идей"/>}
        </div>
    )
})

export default IdeasView