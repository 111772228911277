import {
    Alignment,
    Button,
    Dialog,
    DialogBody,
    DialogFooter,
    FormGroup,
    InputGroup,
    Intent,
    MenuItem
} from "@blueprintjs/core";
import React, {useState} from "react";
import {ItemPredicate, ItemRenderer, Select} from "@blueprintjs/select";
import IShareInfo from "../model/share";
import {useStore} from "../store";

import './new-idea-dialog.scss'

interface NewIdeaDialogProps {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (ticker: string, targetPrice: number, decision: string) => void
}

const NewIdeaDialog = ({isOpen, onClose, onSubmit}: NewIdeaDialogProps) => {

    const store = useStore()

    const [ticker, setTicker] = useState<IShareInfo>()
    const [targetPrice, setTargetPrice] = useState<number>()
    const [decision, setDecision] = useState<string>()
    const decisions = ['BUY', 'HOLD', 'NOT BUY']

    const clearForm = () => {
        setTicker(undefined)
        setTargetPrice(undefined)
        setDecision(undefined)
    }

    const createClicked = () => {
        if (!ticker) return
        if (!targetPrice) return
        if (!decision) return
        onSubmit(ticker.ticker, targetPrice, decision)
        clearForm()
    }

    const closeClicked = () => {
        onClose()
        clearForm()
    }

    const successButtonDisabled = () => {
        return ticker === undefined || targetPrice === undefined || decision === undefined
    }

    const tickerChanged = (share: IShareInfo) => {
        setTicker(share)
    }

    const targetPriceChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        const price = parseFloat(e.target.value)
        if (isNaN(price)) return
        setTargetPrice(price)
    }

    const decisionChanged = (decision: string) => {
        setDecision(decision)
    }

    const footerActions = (
        <>
            <Button onClick={closeClicked}>Close</Button>
            <Button intent={Intent.SUCCESS} disabled={successButtonDisabled()} onClick={createClicked}>Create</Button>
        </>
    );

    const renderTicker: ItemRenderer<IShareInfo> = (share, {handleClick, handleFocus, modifiers, query}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={share.ticker}
                onClick={handleClick}
                onFocus={handleFocus}
                roleStructure="menuitem"
                text={share.ticker + ' (' + share.name + ')'}
            />
        );
    }

    const renderDecision: ItemRenderer<string> = (decision, {handleClick, handleFocus, modifiers, query}) => {
        if (!modifiers.matchesPredicate) {
            return null;
        }
        return (
            <MenuItem
                active={modifiers.active}
                disabled={modifiers.disabled}
                key={decision}
                onClick={handleClick}
                onFocus={handleFocus}
                roleStructure="menuitem"
                text={decision}
            />
        );
    }

    const filterTicker: ItemPredicate<IShareInfo> = (query, share, _index, exactMatch) => {
        const normalizedName = share.name.toLowerCase()
        const normalizedTicker = share.ticker.toLowerCase()
        const normalizedQuery = query.toLowerCase()

        if (exactMatch) {
            return normalizedName === normalizedQuery || normalizedTicker === normalizedQuery;
        } else {
            return normalizedName.indexOf(normalizedQuery) >= 0 || normalizedTicker.indexOf(normalizedQuery) >= 0;
        }
    };


    return (
        <Dialog className="new-idea-dialog bp5-dark" icon="lightbulb" title="Create new idea" isOpen={isOpen} onClose={onClose}>
            <DialogBody>
                <FormGroup label="Эмитент">
                    <Select<IShareInfo>
                        fill
                        itemPredicate={filterTicker}
                        noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem"/>}
                        items={store.tickers}
                        itemRenderer={renderTicker}
                        onItemSelect={tickerChanged}
                        popoverProps={{matchTargetWidth: true, minimal: true}}>
                        <Button text={ticker?.ticker ? ticker.ticker + ' (' + ticker.name + ')' : 'Select share...'} fill
                                alignText={Alignment.LEFT} rightIcon="caret-down"/>
                    </Select>
                </FormGroup>
                <FormGroup
                    label="Целевая цена"
                    labelFor="target-price-input"
                    labelInfo={"(required)"}>
                    <InputGroup id="target-price-input" placeholder="Target price..." onChange={targetPriceChanged}/>
                </FormGroup>
                <FormGroup
                    label="Мнение"
                    labelFor="decision-input"
                    labelInfo={"(required)"}>
                    <Select<string> fill filterable={false}
                        noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem"/>}
                        items={decisions}
                        itemRenderer={renderDecision}
                        onItemSelect={decisionChanged}
                        popoverProps={{matchTargetWidth: true, minimal: true}}>
                        <Button text={decision ? decision : 'Select decision...'} fill
                                alignText={Alignment.LEFT} rightIcon="caret-down"/>
                    </Select>
                </FormGroup>
            </DialogBody>
            <DialogFooter minimal actions={footerActions}/>
        </Dialog>
    )
}

export default NewIdeaDialog