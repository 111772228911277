import {instance} from "./index";

const TickersService= {

    loadTickers() {
        return instance.get('/tickers')
    },

    getShareInfo(ticker: string) {
        return instance.get('/tickers/' + ticker)
    },

    getMdata(ticker: string) {
        return instance.get('/tickers/' + ticker + '/mdata')
    },

    getFutures(ticker: string) {
        return instance.get('/tickers/' + ticker + '/futures')
    },

    getDividends(ticker: string) {
        return instance.get('/tickers/' + ticker + '/dividends')
    },

    getTradeClusters(ticker: string) {
        return instance.get('/tickers/' + ticker + '/trade_clusters')
    }
}

export default TickersService