import {observer} from "mobx-react-lite";
import {useStore} from "../store";
import {BootstrapMode} from "../store/bootstrap";
import {RouterProvider} from "react-router-dom";
import React, {useEffect, useState} from "react";

import './bootstrap.scss'
import {Button, ControlGroup, InputGroup, Intent} from "@blueprintjs/core";

export const Bootstrap = observer(() => {
    const {bootstrap, router} = useStore()

    switch (bootstrap.currentMode()) {
        case BootstrapMode.INIT_MODE:
            return <InitPage/>
        case BootstrapMode.AUTH_MODE:
            return <LoginPage/>
        case BootstrapMode.LOAD_MODE:
            return <LoadPage/>
        case BootstrapMode.APP_MODE:
            return <RouterProvider router={router}/>
        default:
            return <LoginPage/>
    }
})

const InitPage = observer(() => {
    const {bootstrap} = useStore()

    useEffect(() => {
        bootstrap.init()
    }, [bootstrap]);

    return (
        <div className="bootstrap">
            <div className="bootsrap__content">
                <div className="bootstrap__message">{bootstrap.message}</div>
            </div>
        </div>
    )
})

const LoginPage = observer(() => {
    const {bootstrap} = useStore()

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const usernameChanged = (event: any) => {
        setUsername(event.target.value)
    }

    const passwordChanged = (event: any) => {
        setPassword(event.target.value)
    }

    const authenticate = () => {
        bootstrap.login(username, password)
    }

    return (
        <div className="bootstrap">
            <div className="bootstrap__content">
                <div className="bootstrap__logo">
                    <div className="bootstrap__vendor">SHAPITO</div>
                </div>

                <div className="bootstrap__login-form login-form">
                    <form autoComplete="off">
                        <ControlGroup vertical>
                            <InputGroup className="login-form__username"
                                        leftIcon="user" autoFocus large
                                        placeholder="Username"
                                        onChange={usernameChanged}/>
                            <InputGroup className="login-form__password"
                                        type="password" leftIcon="lock" large
                                        placeholder="Password"
                                        onChange={passwordChanged}/>
                        </ControlGroup>
                        <Button className="login-form__signin-button"
                                fill large
                                intent={Intent.PRIMARY}
                                onClick={authenticate}>Sign in</Button>
                    </form>
                </div>
            </div>
        </div>
    )
})

const LoadPage = observer(() => {
    const {bootstrap} = useStore()

    useEffect(() => {
        bootstrap.load()
    }, [bootstrap]);

    return (
        <div className="bootstrap">
            <div className="bootsrap__content">
                <div className="bootstrap__message">{bootstrap.message}</div>
            </div>
        </div>
    )
})