import { instance } from "./";

const AuthService = {

    login (username: string, password: string) {
        return instance.post("/auth/login", {username, password})
    },

    logout() {
        return instance.post("/auth/logout")
    },

    loadCurrentUser() {
        return instance.get("/auth/current-user")
    }
}

export {AuthService}