import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import 'normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import {FocusStyleManager} from "@blueprintjs/core";
import {RootStore, RootStoreContext} from "./store";
import {router} from "./route";
import {Bootstrap} from "./bootstrap/bootstrap";

import './index.css';

FocusStyleManager.onlyShowFocusOnTabs();

const rootStore = new RootStore(router)

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <RootStoreContext.Provider value={rootStore}>
            <Bootstrap/>
        </RootStoreContext.Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
