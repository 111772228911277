import { instance } from "./";

const BootstrapService = {

    ping() {
        return instance.get("/ping")
    }

}

export default BootstrapService