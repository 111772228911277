import './futures-view.scss'
import React, {useEffect, useState} from "react";
import {useStore} from "../store";
import {observer} from "mobx-react-lite";
import {H3, HTMLTable} from "@blueprintjs/core";
import {Link} from "react-router-dom";

function prettifyValue(value: number): string {
    if (value >= 1000 && value < 1000000) {
        return (value / 1000).toFixed(2).toString() + "K"
    } else if (value >= 1000000 && value < 1000000000) {
        return (value / 1000000).toFixed(2).toString() + "M"
    } else if (value >= 1000000000) {
        return (value / 1000000000).toFixed(2).toString() + "B"
    }
    return "-"
}


const FuturesView = observer(() => {

    const store = useStore()
    const [futures, setFutures] = useState<any[]>()

    useEffect(() => {
        store.getStockFutures().then(futures => {
            setFutures(futures)
        }).catch(error => {
            console.error(error)
        })
    }, [store]);

    return (
        <div className="futures-view">
            <H3>Фьючерсы</H3>
            <HTMLTable compact striped interactive bordered>
                <thead>
                <tr>
                    <th>ID</th>
                    <th>База</th>
                    <th>Название</th>
                    <th>Лотность</th>
                    <th>Гар. обеспечение</th>
                    <th>Спот. цена</th>
                    <th>Контанго</th>
                    <th>Последняя цена</th>
                    <th>Справедливая цена</th>
                    <th>Объем</th>
                    <th>Экспирация</th>
                </tr>
                </thead>
                <tbody>
                {futures?.map(future => {
                    return (
                        <tr>
                            <td>{future.sec_id}</td>
                            <td><Link to={'/shares/' + future.info.asset_code}>{future.info.asset_code}</Link></td>
                            <td>{future.info.short_name}</td>
                            <td>{future.info.lot_volume}</td>
                            <td>{future.info.initial_margin}</td>
                            <td>{future.spot_price}</td>
                            <td>{future.delta}</td>
                            <td>{future.set_tle_price}</td>
                            <td>{future.fair_price || '-'}</td>
                            <td>{prettifyValue(future.value)}</td>
                            <td>{future.info.last_del_date}&nbsp;({future.days_left} д.)</td>
                        </tr>
                    )
                })}
                </tbody>
            </HTMLTable>
        </div>
    )
})

export default FuturesView