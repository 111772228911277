import { instance } from "./";

const IdeasService = {

    load(userName: string | undefined) {
        if (userName) {
            return instance.get("/ideas/" + userName)
        } else {
            return instance.get("/ideas")
        }
    },

    create(ticker: string, targetPrice: number, decision: string) {
        return instance.post("/ideas", {ticker, target_price: targetPrice, decision})
    }

}

export default IdeasService