import axios from "axios";

export const instance = axios.create({
    withCredentials: true,
    baseURL: "/api/v1",
});

instance.interceptors.request.use(
    (config) => {
        config.headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`
        return config
    }
)

instance.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = {...error.config};
        originalRequest._isRetry = true;
        throw error;
    }
);