import {instance} from "./index";

const StockService = {

    loadDailyView() {
        return instance.get("/stock/daily")
    },

    getFutures() {
        return instance.get('/stock/futures')
    },
    getShares() {
        return instance.get('/stock/shares')
    }
}

export default StockService