import {Router} from "@remix-run/router";
import {makeAutoObservable} from "mobx";
import {createContext, useContext} from "react";
import IUser from "../model/user";
import {BootstrapStore} from "./bootstrap";
import IdeasService from "../api/ideas";
import StockService from "../api/stock";
import TickersService from "../api/tickers";
import SubscriptionsService from "../api/subscriptions";


class RootStore {

    isAuthenticated = false;
    router: Router;
    ideas: [];
    dailyView: { [name: string]: any }
    tickers: []
    subscriptions: []
    sidebarVisible: boolean;

    currentUser: IUser | undefined;

    bootstrap: BootstrapStore;

    constructor(router: Router) {
        this.router = router;
        this.bootstrap = new BootstrapStore(this);
        this.ideas = []
        this.tickers = []
        this.dailyView = {'grown': [], 'fallen': [], 'high_value': [], 'low_value': [], 'imoex': []}
        this.subscriptions = []
        this.sidebarVisible = true;
        makeAutoObservable(this);
    }

    setCurrentUser(user: IUser) {
        this.currentUser = user
        this.isAuthenticated = true
    }

    init(userName: string | undefined) {
        IdeasService.load(userName).then(response => {
            this.ideas = response.data
        })
        TickersService.loadTickers().then(response => {
            this.tickers = response.data
        })

    }

    async logout() {
        console.log('logout called')
        this.currentUser = undefined
        this.isAuthenticated = false
        localStorage.removeItem('access_token')
        this.bootstrap.init()
    }

    loadDailyStockView() {
        console.log('loading daily stock view')
        StockService.loadDailyView().then(response => {
            this.dailyView = response.data
        }).catch(error => {
            console.error(error)
        })
    }

    createIdea(ticker: string, targetPrice: number, decision: string) {
        IdeasService.create(ticker, targetPrice, decision).then(async response => {
            IdeasService.load(undefined).then(response => {
                this.ideas = response.data
            })
        }).catch(error => {
            console.error(error)
        })
    }

    loadSubscriptions() {
        SubscriptionsService.loadSubscriptions().then(response => {
            this.subscriptions = response.data
        }).catch(error => {
            console.error(error)
        })
    }

    async getShareInfo(ticker: string) {
        return await TickersService.getShareInfo(ticker).then(response => {
            return response.data
        })
    }

    async getShareMdata(ticker: string) {
        return await TickersService.getMdata(ticker).then(response => response.data)
    }

    async getShareFutures(ticker: string) {
        return await TickersService.getFutures(ticker).then(response => response.data)
    }

    async getDividends(ticker: string) {
        let response = await TickersService.getDividends(ticker);
        return await response.data;
    }

    async getStockFutures() {
        let response = await StockService.getFutures();
        return await response.data;
    }

    async getShares() {
        return await StockService.getShares().then(response => response.data)
    }

    toggleSidebarVisible() {
        this.sidebarVisible = !this.sidebarVisible;
    }

    async getTradeClusters(ticker: string) {
        let response = await TickersService.getTradeClusters(ticker);
        return await response.data
    }
}

const RootStoreContext = createContext<RootStore | null>(null)

const useStore = () => {
    const context = useContext(RootStoreContext);
    if (context === null) {
        throw new Error("You have forgotten to wrap your root component with RootStoreProvider");
    }
    return context;
};

export {RootStore, RootStoreContext, useStore}